.icon-left
{
    position: absolute;
    left: 10px;
    top: 10px;
}

.icon-right
{
    position: absolute;
    right: 10px;
    bottom: 10px;    
}

.season-display.summer i {
    color: red;
}

.season-display.winter i {
    color: blue;
}

.season-display {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.winter {
   background-color: aliceblue;
}
  
.summer {
background-color: orange;
}